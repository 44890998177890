const api_root = 'https://stream.gbb333.com';
const history = {};

export default {
  history: history,


  getBars: function (symbolInfo, resolution, from, to, first) {
    return new Promise((resolve, reject) => {
      if (resolution === '1') {
        history[symbolInfo.name] = {}; // 1분 데이터로 전환할 때 상태 초기화
      }

      // URL에서 쿼리 파라미터 추출
      const urlParams = new URLSearchParams(window.location.search);
      const coin = urlParams.get('coin') || 'BTC';  // coin 값을 가져오거나 기본값으로 'BTC' 설정

      fetch(`${api_root}/api/bars?coin=${coin}`) // coin 값을 서버에 전달
        .then((response) => response.json())
        .then((data) => {
          if (data && data.data.length) {
            const market = data.market;

            // 받아온 데이터를 변환
            var bars = data.data.map((el) => {
              let adjustedTime = el.time;
              
              // time이 10자리인 경우 마지막 두 자리를 00으로 고정
              if (adjustedTime.toString().length === 10) {
                const timeStr = adjustedTime.toString().slice(0, -2) + '00'; // 마지막 두 자리를 '00'으로 변경
                adjustedTime = Number(timeStr); // 다시 숫자로 변환
              }
              
              return {
                time: adjustedTime * 1000, // 시간은 밀리초로 변환
                low: el.low,
                high: el.high,
                open: el.open,
                close: el.close,
                volume: el.volume,
                
              }
              
            });

            if (first) {
              var lastBar = bars[bars.length - 1];
              history[symbolInfo.name] = { lastBar: lastBar, market: market };
              console.log('Last bar set:', lastBar);
            }

            resolve(bars);
          } else {
            console.warn(`No data returned from API for ${symbolInfo.name}.`);
            resolve([]);
          }
        })
        .catch((err) => {
          console.error('API request error:', err);
          if (!history[symbolInfo.name]) {
            history[symbolInfo.name] = { lastBar: { time: Date.now(), close: 0 } };
          }
          reject(err);
        });
    });
  },
};
